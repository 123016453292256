<template>
    <div class="main">
        <div class="top-img">
            <van-image class="top-img-bg" v-if="liveData.img" :src="liveData.img" fit="cover"></van-image>
            <div v-else class="top-img-bg"></div>
        </div>
        <div class="enter-live-box">
            <div class="live-icon">
                <span style="font-weight: bold">·</span> 直播
            </div>
            <div @click="toLiveRoom" v-if="userId === 'Wangleiqiang'" class="enter-live-icon">立即进入直播间 <van-icon name="arrow" /></div>
        </div>
        <div class="live-title">{{liveData.theme}}</div>
        <div class="live-time">{{liveData.timeDate}}</div>
        <div class="live-name">直播人<span style="font-weight: bold">·</span>{{liveData.userName}}</div>
        <div class="bottom-button">
            <div @click="toShareShop" class="enter-page-button-3">发送通知</div>
        </div>
        <div class="bottom-button-2">
            <div @click="toShareWork" class="enter-page-button">分享到企业微信</div>
        </div>
        <div class="bottom-button-3">
            <div @click="toShare" class="enter-page-button-2">分享到微信</div>
        </div>
        <!--进入直播间弹窗开始-->
        <!--<div class="enter-page-box">
        </div>-->
        <!--进入直播间弹窗结束-->
    </div>
</template>
<script>
    import { getQxJsSdkConfig, getLiveList, getQxUserId, getAllConfig } from '@/api/live'
    import { Toast } from 'vant';
    import Cookies from "js-cookie";
    const wx = window.wx;
    export default {
        name: 'Share',
        components: {
        },
        data() {
            return {
                id: '',
                userId: '',
                liveId: '', // 直播id
                showEnter: true, // 是否展示进入直播间弹窗
                unionId : '',
                openId : '',
                pathUrl: '',
                liveData: '',
                jsSdkPath: 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
            }},
        created() {
            const query = this.$route.query
            let liveId = query.liveId
            let id = query.id
            if(!liveId || !id){
                Toast('参数错误')
                return
            }
            // 判断是微信打开  还是企业微信打开
            let ua = navigator.userAgent.toLowerCase();
            console.log(ua)
            let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
            if(isWx && ua.match(/WxWork/i) == "wxwork"){
                console.log("企业微信环境")
            }else if(isWx){
                let url = "https://qzone.renkangshangcheng.cn/living/#/share?id=" + query.id + "&liveId=" + query.liveId;
                console.log(url)
                window.location.href=url
                return;
            }
            this.liveId = liveId
            this.id = id
            let userId=Cookies.get('userId')
            if(!userId){
                this.login()
            }else{
                this.userId = userId
                this.getLiveData()
                this.getConfig()
                // this.getAgentConfig();
            }
        },
        mounted:function(){
            // this.insertScriptTag()
        },
        methods: {
            // 动态加载js文件
            insertScriptTag () {
                const _this = this
                let playerScriptTag = document.getElementById('jsSdkScriptTag')
                // 如果这个tag不存在，则生成相关代码tag以加载代码
                if (playerScriptTag === null) {
                    playerScriptTag = document.createElement('script')
                    playerScriptTag.type = 'text/javascript'
                    playerScriptTag.src = this.jsSdkPath
                    playerScriptTag.id = 'jsSdkScriptTag'
                    let s = document.getElementsByTagName('head')[0]
                    s.appendChild(playerScriptTag)
                }
                if (playerScriptTag.loaded) {
                    _this.getConfig()
                } else {
                    playerScriptTag.addEventListener('load', () => {
                        playerScriptTag.loaded = true
                        _this.getConfig()
                    })
                }
            },
            // 获直播详情
            getLiveData(){
                let postData = {}
                postData.id = this.id
                getLiveList(postData).then(response => {
                    console.log(response)
                    this.liveData = response.data
                    response.data.reserveStart = response.data.reserveStart.replace(/-/g, '/')
                    response.data.week = this.getweekday(response.data.reserveStart)
                    const date = new Date(response.data.reserveStart);
                    response.data.month = date.getMonth() + 1
                    response.data.day = date.getDate()
                    response.data.beginHour = date.getHours().toString().padStart(2, "0")
                    response.data.beginMinutes = date.getMinutes().toString().padStart(2, "0")
                    // 获取结束时间
                    const endTime = new Date(response.data.reserveStart).getTime() + response.data.reserveLivingDuration * 1000
                    const endDate = new Date(endTime)
                    response.data.endHour = endDate.getHours().toString().padStart(2, "0")
                    response.data.endMinutes = endDate.getMinutes().toString().padStart(2, "0")
                    this.liveData.timeDate = response.data.month + '月' +response.data.day + '日' + ' ' + response.data.beginHour + ':' + response.data.beginMinutes + ' - ' + response.data.endHour + ':' + response.data.endMinutes
                })
            },
            // 计算指定时间是星期几
            getweekday(date){
                // date例如:'2022-03-05'
                var weekArray = ["周日","周一", "周二", "周三", "周四", "周五", "周六"]
                var week  = weekArray[new Date(date).getDay()]
                return week
            },
            // 获取config信息
            getConfig(){
                let postData={}
                postData.url=window.location.href.split('#')[0]
                postData.agentId = 1000092
                getAllConfig(postData).then(response => {
                    this.initConfig(response.data)
                })
            },
            initConfig(params){
                var _this = this;
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: 'wwf93ebffd5d31aa7a', // 必填，企业微信的corpID
                    timestamp: params.timestamp, // 必填，生成签名的时间戳
                    nonceStr: params.noncestr, // 必填，生成签名的随机串
                    signature: params.sign,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList: [
                        "scanQRCode", //1
                    ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    success: function (res) {
                        Toast("config成功");
                        console.log(JSON.stringify(res));
                        // 配置agentConfig
                        _this.getAgentConfig();
                    },
                    fail: function (res) {
                        console.log(res);
                        if (res.errMsg.indexOf("function not exist") > -1) {
                            alert("版本过低请升级");
                        }
                    },
                });
            },
            // 获取jsSdk配置信息
            getAgentConfig(){
                let postData={}
                postData.url=window.location.href.split('#')[0]
                postData.agentId = 1000092
                getQxJsSdkConfig(postData).then(response => {
                    this.initAgentConfig(response.data)
                })
            },
            // 初始化 agentConfig
            initAgentConfig(jsSdkConfig) {
                console.log(jsSdkConfig)
                wx.agentConfig({
                    beta: true,
                    corpid: jsSdkConfig.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                    agentid: jsSdkConfig.agentId, // 必填，企业微信的应用id （e.g. 1000247）
                    timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
                    signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                    jsApiList: [
                        'hideOptionMenu',
                        'shareWechatMessage',
                        'onMenuShareAppMessage',
                        'onMenuShareWechat',
                        'onMenuShareTimeline',
                        'shareAppMessage',
                        'startLiving',
                    ], //必填，传入需要使用的接口名称
                    success: function() {
                        // 回调
                        console.log('初始化agent 成功')
                    },
                    fail: function(res) {
                        console.log(res)
                        if(res.errMsg.indexOf('function not exist') > -1){
                            alert('版本过低请升级')
                        }
                    }
                });
                wx.ready(function () {
                    //禁用右上角菜单按钮
                    wx.hideOptionMenu();
                })
            },
            // 进入直播间
            toLiveRoom(){
                let _this = this
                wx.invoke('startLiving', {
                    "livingId": _this.liveId,
                }, function(res) {
                    if (res.err_msg == "startLiving:ok") {
                        console.log(res)
                    }
                });
            },
            // 分享到微信
            toShare() {
                const postData = {}
                postData.title = this.liveData.theme
                postData.desc = this.liveData.description? this.liveData.description: '直播人：' + this.liveData.userName
                postData.link = 'https://qzone.renkangshangcheng.cn/living/#/share?liveId=' +this.liveId+ '&id=' + this.id
                postData.imgUrl = this.liveData.img?this.liveData.img:'https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/16546807931942.png'
                console.log(postData)
                wx.invoke(
                    "shareWechatMessage", postData, function(res) {
                        console.log(res)
                        if (res.err_msg == "shareWechatMessage:ok") {
                            Toast('分享成功')
                        }
                    }
                );
            },
            // 分享给员工
            toShareWork() {
                const postData = {}
                postData.title = this.liveData.theme
                postData.desc = this.liveData.description? this.liveData.description: '直播人：' + this.liveData.userName
                postData.link = 'https://qzone.renkangshangcheng.cn/living/#/share?liveId=' +this.liveId+ '&id=' + this.id
                postData.imgUrl = this.liveData.img?this.liveData.img:'https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/16546807931942.png'
                console.log(postData)
                wx.invoke(
                    "shareAppMessage", postData, function(res) {
                        console.log(res)
                        if (res.err_msg == "shareWechatMessage:ok") {
                            Toast('分享成功')
                        }
                    }
                );
            },
            // 分享给企业员工
            toShareShop() {
                let postData = {}
                postData.id = this.id
                postData.liveId = this.liveId
                this.$router.push({  path: 'department', query: postData});
                /*const postData = {}
                postData.title = this.liveData.theme
                postData.desc = this.liveData.description? this.liveData.description: '直播人：' + this.liveData.userName
                postData.link = 'https://qzone.renkangshangcheng.cn/living/#/shopShare?liveId=' +this.liveId+ '&id=' + this.id
                postData.imgUrl = 'https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/16546807931942.png'
                console.log(postData)
                wx.invoke(
                    "shareAppMessage", postData, function(res) {
                        console.log(res)
                        if (res.err_msg == "shareWechatMessage:ok") {
                            Toast('分享成功')
                        }
                    }
                );*/
            },
            // 用户网页授权登录
            login(){
                let code= this.getCode("code");
                if(code){
                    let postData={}
                    postData.code=code
                    postData.agentId=1000092
                    getQxUserId(postData).then(response => {
                        if(response.code===200 || response.code===0){
                            Cookies.set('userId', response.data.userId)
                            this.userId=response.data.userId
                            this.getAllLive()
                        }else{
                            Toast.fail('获取userId失败');
                            return false
                        }
                    })
                }else{
                    let nowUrl = window.location.href;
                    let backurl = encodeURIComponent(nowUrl)
                    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                    window.location.href=url
                }
            },
            // 截取code
            getCode(parameName) {
                /// 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                var parames = window.location.search;
                console.log(parames);
                // 检测参数是否存在
                if (parames.indexOf(parameName) > -1) {
                    var parameValue = "";
                    parameValue = parames.substring(
                        parames.indexOf(parameName),
                        parames.length
                    );
                    // 检测后面是否还有参数
                    if (parameValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                        parameValue = parameValue.substring(0, parameValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        parameValue = parameValue.replace(parameName + "=", "");
                        return parameValue;
                    }
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
            },
            // 获取参数
            GetUrl(parameName) {
                /// 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                var parames = window.location.hash;
                // 检测参数是否存在
                if (parames.indexOf(parameName) > -1) {
                    var parameValue = "";
                    parameValue = parames.substring(
                        parames.indexOf(parameName),
                        parames.length
                    );
                    // 检测后面是否还有参数
                    if (parameValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                        parameValue = parameValue.substring(0, parameValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        parameValue = parameValue.replace(parameName + "=", "");
                        return parameValue;
                    }
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
    }
    .top-img-bg{
        width: 100vw;
        height: 200px;
        background-color: #9bc3f5;
    }
    .live-icon{
        width: 50px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #5DA3FA;
        padding: 5px 5px;
        color: #ffffff;
        border-radius: 5px;
        font-size: 12px;
        margin: 20px 0 0 20px;
    }
    .enter-live-icon{
        width: 110px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #08ba7c;
        padding: 5px 5px;
        color: #ffffff;
        border-radius: 5px;
        font-size: 12px;
        margin: 20px 20px 0 0;
    }
    .live-title{
        font-size: 28px;
        font-weight: bold;
        margin: 20px 0 0 20px;
    }
    .live-time{
        margin: 20px 0 0 20px;
    }
    .live-name{
        color: #898787;
        margin: 20px 0 0 20px;
        font-size: 13px;
    }
    .bottom-button{
        position: fixed;
        bottom: 190px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .bottom-button-2{
        position: fixed;
        bottom: 120px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .bottom-button-3{
        position: fixed;
        bottom: 50px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .enter-page-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1002;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 7, 7, 0.9);
    }
    .enter-page-button-2{
        color: #5DA3FA;
        border: 1px solid #5DA3FA;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 200px;
        font-size: 18px;
        z-index: 1003;
    }
    .enter-page-button-3{
        color: #ffffff;
        border: 1px solid #08d8ea;
        background-color: #08d8ea;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 200px;
        font-size: 18px;
        z-index: 1003;
    }
    .enter-page-button{
        color: #ffffff;
        border: 1px solid #5DA3FA;
        background-color: #5DA3FA;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 200px;
        font-size: 18px;
        z-index: 1003;
    }
    .enter-live-box{
        display: flex;
        justify-content: space-between;
    }
</style>
